import * as React from "react";
import type { HeadFC } from "gatsby";

const IndexPage = () => {
  return <></>;
};

export default IndexPage;

export const Head: HeadFC = () => {
  return (
    <>
      <title>BuildBetter</title>
      <meta http-equiv="Refresh" content="0; url='https://buildbetter.ai/'" />
    </>
  );
};
